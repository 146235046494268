import { ComponentWithChildrenProps } from "lib/shared/props";
import React from "react";
import { VStack } from "lib/ui/Stack";
import { Topbar } from "./Topbar";

export const Navigation = ({ children }: ComponentWithChildrenProps) => {
  return (
    <VStack fullHeight fullWidth alignItems="start">
      <Topbar />
      {children}
    </VStack>
  );
};
