import styled from "styled-components"
import React from "react"
import { Logo } from "./Logo"
import { PageSlice } from "lib/ui/page/PageSlice"
import { HStack } from "lib/ui/Stack"
import { useIsScreenWidthLessThan } from "lib/ui/hooks/useIsScreenWidthLessThan"
import { ScreenCover } from "lib/ui/ScreenCover"
import { PrimaryNavigation } from "./PrimaryNavigation"
import { OverlayOpener } from "lib/ui/OverlayOpener"
import { Burger } from "lib/ui/Navigation/Topbar/Burger"
import { Sidebar } from "./Sidebar"
import { ExternalLink } from "lib/navigation/Link/ExternalLink"
import { increaserWebsite } from "shared/externalResources"
import { PrimaryButton } from "lib/ui/buttons/rect/PrimaryButton"
import { ThemeToggleButton } from "lib/ui/theme/ThemeToggleButton"
import { NavigationText } from "lib/ui/Navigation/NavigationText"

const Container = styled(PageSlice)`
  height: 80px;
`

const Cover = styled(ScreenCover)`
  justify-content: flex-start;
`

export const Topbar = () => {
  const isSmallScreen = useIsScreenWidthLessThan(600)

  return (
    <Container>
      <HStack fullWidth alignItems="center" justifyContent="space-between">
        <HStack alignItems="center" gap={60}>
          <Logo />
          {!isSmallScreen && (
            <HStack alignItems="center" gap={40}>
              <PrimaryNavigation
                renderItem={({ name }) => <NavigationText text={name} />}
              />
            </HStack>
          )}
        </HStack>
        {isSmallScreen ? (
          <OverlayOpener
            renderOpener={({ onOpen }) => <Burger onClick={onOpen} />}
            renderOverlay={({ onClose }) => (
              <Cover onClick={onClose}>
                <Sidebar />
              </Cover>
            )}
          />
        ) : (
          <HStack alignItems="center" gap={20}>
            <ThemeToggleButton />
            <ExternalLink to={increaserWebsite}>
              <PrimaryButton as="div" kind="attention">
                Get Productive
              </PrimaryButton>
            </ExternalLink>
          </HStack>
        )}
      </HStack>
    </Container>
  )
}
