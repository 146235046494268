import { InternalLink } from "lib/navigation/Link/InternalLink"
import { Text } from "lib/ui/Text"
import React from "react"
import { Path } from "./Path"

export const Logo = () => (
  <InternalLink to={Path.Home}>
    <Text weight="bold" size={24}>
      Radzion
      <Text as="span" color="supporting2">
        .com
      </Text>
    </Text>
  </InternalLink>
)
