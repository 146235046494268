import React, { ReactNode } from "react"
import { Link, GatsbyLinkProps } from "gatsby"

import { unstyledAnchorCSS } from "./UnstyledAnchor"
import styled from "styled-components"

const UnstyledLink = styled(Link)`
  ${unstyledAnchorCSS}
`

export function InternalLink<TState>({
  to,
  children,
  ...rest
}: React.PropsWithoutRef<GatsbyLinkProps<TState>>) {
  return (
    <UnstyledLink to={to} {...rest}>
      {children}
    </UnstyledLink>
  )
}
