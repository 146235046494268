import React from "react"
import { UnstyledAnchor } from "./UnstyledAnchor"

type Props = React.ComponentProps<typeof UnstyledAnchor> & {
  to: string
  openInSameTab?: boolean
}

export const ExternalLink = ({ to, openInSameTab = false, ...rest }: Props) => {
  return (
    <UnstyledAnchor
      target={openInSameTab ? undefined : "_blank"}
      // rel={`noopener${!isReferring && ' noreferrer'}`}
      rel="noopener"
      href={to}
      {...rest}
    />
  )
}
