export const founderWebsite = "https://radzion.com"
export const increaserWebsite = "https://increaser.org"
export const youTubeDevChannel = "https://www.youtube.com/c/Radzion"
export const youTubeChannel =
  "https://www.youtube.com/channel/UCvYgieIcIvmn_fThGIS7fCw"
export const booksConceptsWebsite = "https://booksconcepts.com"
export const reactKitWebsite = "https://kit.radzion.com"
export const founderTwitter = "https://twitter.com/radzionc"
export const founderLinkedIn = "https://www.linkedin.com/in/radzion"
export const founderEmail = "radzion@radzion.com"
export const founderTelegram = "https://t.me/radzionc"
