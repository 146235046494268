import styled from "styled-components"
import { getCSSUnit } from "../utils/getCSSUnit"

export const defaultMaxWidth = 1140

export const PageSlice = styled.div<{ maxWidth?: number }>`
  display: grid;
  width: 100%;
  --column-gap: 20px;
  grid-template-columns:
    1fr min(
      ${({ maxWidth = defaultMaxWidth }) => getCSSUnit(maxWidth)},
      100% - calc(var(--column-gap) * 2)
    )
    1fr;
  grid-column-gap: var(--column-gap);
  > * {
    grid-column: 2;
  }
`
