import styled from "styled-components";
import { VStack } from "lib/ui/Stack";
import React from "react";
import { PrimaryNavigation } from "./PrimaryNavigation";
import { NavigationItem } from "lib/ui/Navigation/Sidebar/NavigationItem";

const Container = styled.div`
  min-width: 260px;
  height: 100%;
  background: ${({ theme }) => theme.colors.foreground.toCssValue()};
  padding: 40px 8px 20px 8px;
  overflow: auto;
`;

export const Sidebar = () => {
  return (
    <Container>
      <VStack alignItems="center" fullHeight justifyContent="space-between">
        <VStack fullWidth>
          <PrimaryNavigation
            renderItem={({ name }) => <NavigationItem name={name} />}
          />
        </VStack>
      </VStack>
    </Container>
  );
};
