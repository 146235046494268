import React from "react"
import styled, { css } from "styled-components"

interface Props {
  isUnderlined?: boolean
}

export const unstyledAnchorCSS = css<Props>`
  color: inherit;
  cursor: pointer;
  text-decoration: ${({ isUnderlined }) =>
    isUnderlined ? "underline" : "none"};
`

export const UnstyledAnchor = styled.a<Props>`
  ${unstyledAnchorCSS}
`
