import { defaultTransitionCSS } from "lib/ui/animations/transitions"
import { HStack } from "lib/ui/Stack"
import { Text } from "lib/ui/Text"
import React, { ReactNode } from "react"
import styled from "styled-components"

interface Props {
  text: ReactNode
  icon?: ReactNode
}

const Container = styled.div`
  color: ${({ theme }) => theme.colors.text.toCssValue()};

  :after {
    ${defaultTransitionCSS};
    background: none repeat scroll 0 0
      ${({ theme }) => theme.colors.text.toCssValue()};
    content: "";
    display: block;
    height: 2px;
    width: 0;
  }
  &:hover:after {
    width: 100%;
  }
`

export const NavigationText = ({ text, icon }: Props) => {
  return (
    <Container>
      <HStack alignItems="center" gap={8}>
        {icon}
        <Text weight="semibold" as="span">
          {text}
        </Text>
      </HStack>
    </Container>
  )
}
