import { WrapPageElementBrowserArgs } from "gatsby"
import "prismjs/plugins/command-line/prism-command-line.css"
import React from "react"
import { ThemeProvider } from "./src/ui/ThemeProvider"
import { GlobalStyle } from "./src/lib/ui/GlobalStyle"
import { Navigation } from "./src/navigation"

export const wrapPageElement = ({ element }: WrapPageElementBrowserArgs) => {
  return (
    <ThemeProvider>
      <GlobalStyle />
      <Navigation>{element}</Navigation>
    </ThemeProvider>
  )
}
